export default [
  {
    path: "/",
    text: "home",
  },
  {
    path: "/episodes",
    text: "episodes",
  },
  {
    path: "/sponsors",
    text: "sponsors",
  },
  // {
  //   path: "/blog",
  //   text: "blog",
  // },
  // {
  //   path: "/blogs",
  //   text: "blogs",
  // },
  {
    path: "/contact",
    text: "contact",
  },
]
