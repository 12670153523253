import React from "react"
import {
  FaFacebook,
  FaTwitterSquare,
  FaInstagram,
  FaSpotify,
  FaGoogle,
  FaApple,
  FaYoutube,
} from "react-icons/fa"

export default [
  {
    icon: <FaFacebook />,
    url: "https://www.facebook.com/Yewwwtoob-108166833948364/",
  },
  {
    icon: <FaTwitterSquare />,
    url: "https://twitter.com/yewwwtoob",
  },
  {
    icon: <FaInstagram />,
    url: "https://www.instagram.com/yewww_toob/",
  },
  {
    icon: <FaGoogle />,
    url:
      "https://www.google.com/podcasts?feed=aHR0cHM6Ly9hbmNob3IuZm0vcy9jOTIzODEwL3BvZGNhc3QvcnNz",
  },
  {
    icon: <FaSpotify />,
    url: "https://open.spotify.com/show/2UkmUNj6S88118D64e28ni",
  },
  {
    icon: <FaApple />,
    url: "https://podcasts.apple.com/us/podcast/yewwwtoob/id1487324064",
  },
  {
    icon: <FaYoutube />,
    url: "https://www.youtube.com/channel/UCX0zkbdpllaa3h-OZsJhSLQ",
  },
]
